
















import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ContentCardImage extends Vue {
  @Prop({ type: String, required: true }) readonly src!: string;
  @Prop({ type: String, required: false }) readonly title: string | undefined;
  @Prop({ type: String, required: false }) readonly subtitle:
    | string
    | undefined;
}
