







































import { Component, Prop, Vue } from "vue-property-decorator";
import ContentCardImage from "./ContentCardImage.vue";

@Component({
  components: {
    ContentCardImage,
  },
})
export default class ContentCard extends Vue {
  @Prop({ type: String, required: false }) readonly title: string | undefined;
  @Prop({ type: String, required: false }) readonly imageSrc:
    | string
    | undefined;
  @Prop({ type: String, default: "top" }) readonly imagePosition!: string;
  @Prop({ type: String, required: false }) readonly imageTitle:
    | string
    | undefined;
  @Prop({ type: String, required: false }) readonly imageSubtitle:
    | string
    | undefined;
}
